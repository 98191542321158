import React, { useEffect, useState, useCallback, useRef, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { CFormInput, CImage, CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useSnapshot } from 'valtio';
import locales from '../../locales/en.json';
import store from '../../state/store';
import logsState from '../../state/logs/logsState';
import Filter from '../../assets/img/filter.svg';
import SqlControls from '../sql-controls/SqlControls';
import './TopControls.css';
import formState from '../../state/formState/formState';
import NewFormButton from './../flex-form/NewFormButton';
import TabContainer from '../sql-controls/TabContainer';

interface TopControlsProps {
    onSearchSubmit?: (parsedQuery: any) => void;
}

interface Column {
    key: string;
    label: string;
    _props: { scope: string };
}

const TopControls: React.FC<TopControlsProps> = ({ onSearchSubmit }) => {
    const navigate = useNavigate();
    const topControlsTexts = locales.components['topControls'];
    const ListView = useSnapshot(store.listView);
    const HeaderNav = useSnapshot(store.headerNav);
    const { token } = useSnapshot(store.sessionState);
    const { formType } = useSnapshot(store.formState);
    const { goBack } = HeaderNav;
    const { searchValue, setSearchValue, setVisibleColumns, exportCSV, exportJSON, listData } = ListView;
    const [columnsMenuVisible, setColumnsMenuVisible] = useState(false);
    const [allColumns, setAllColumns] = useState<Column[]>([]);
    const [visibleColumns, setVisibleColumnsState] = useState<Column[]>([]);
    const [selectedField] = useState<string>('type');
    const columnsMenuRef = useRef<HTMLDivElement>(null);


    const generateColumns = useCallback((dataSample: any): Column[] => {
        return Object.keys(dataSample).map(key => ({
            key: key,
            label: key.toUpperCase(),
            _props: { scope: 'col' },
        }));
    }, []);

    useEffect(() => {
        if (listData && listData.length > 0) {
            const columns = generateColumns(listData[0]);
            setAllColumns(columns.filter(col => col.key.toLowerCase() !== 'username'));
            setVisibleColumnsState(columns.slice(0, 6));
        } else {
            setVisibleColumnsState([]);
        }
    }, [listData, generateColumns]);

    useEffect(() => {
        setVisibleColumns(visibleColumns);
    }, [visibleColumns, selectedField, setVisibleColumns]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (columnsMenuRef.current && !columnsMenuRef.current.contains(event.target as Node)) {
                setColumnsMenuVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleToggleColumn = useCallback((columnKey: string) => {
        setVisibleColumnsState(prevState => {
            const columnIndex = prevState.findIndex(col => col.key === columnKey);
            if (columnIndex !== -1) {
                return prevState.filter(col => col.key !== columnKey);
            } else {
                const columnToAdd = allColumns.find(col => col.key === columnKey);
                const updatedColumns = [...prevState, columnToAdd!];
                return updatedColumns.sort((a, b) => allColumns.indexOf(a) - allColumns.indexOf(b));
            }
        });
    }, [allColumns]);

    const toggleColumnsMenu = () => {
        setColumnsMenuVisible(prevState => !prevState);
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const goToPreviousPage = () => {
        goBack();
        if (HeaderNav.navArray.length > 1) {
            const previousHref = HeaderNav.navArray[HeaderNav.navArray.length - 2].href;
            navigate(previousHref);
        }
    };

    const handleOpenForm = () => {
        if (formState.setFormDisplay) {
            formState.setFormDisplay(true);
        } else {
            console.error('setFormDisplay is undefined');
        }
    };

    const handleExportFullQuery = async () => {
        if (!token) {
            console.error('No authorization token found');
            return;
        }

        await logsState.exportFullQuery(token);
    };

    return (
        <div className="top-controls">
            <div className='d-flex w-100 justify-content-between mt-2' style={{ height: '3.89vh' }}>
                <div className='d-flex justify-content-start'>
                    <CButton className='table-filter' onClick={goToPreviousPage} >
                        <FontAwesomeIcon className='back-nav' icon={faChevronLeft} />
                    </CButton>
                    <div ref={columnsMenuRef}>
                        <CButton className='table-filter' onClick={toggleColumnsMenu}>
                            <CImage className='filter-icon' src={Filter} />
                        </CButton>
                        {columnsMenuVisible && (
                            <div id="columnsMenu" className="columns-menu">
                                {allColumns.map(col => (
                                    <div className="checkbox-container" key={col.key}>
                                        <input
                                            type="checkbox"
                                            checked={visibleColumns.some(visibleCol => visibleCol.key === col.key)}
                                            onChange={() => handleToggleColumn(col.key)}
                                        />
                                        {col.label}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {!onSearchSubmit && (
                        <>
                            {formType !== '' && formType !== 'alerts' && formType !== 'agents' && <NewFormButton onClick={handleOpenForm} />}
                        </>
                    )}
                    <CDropdown className='export-dropdown' alignment="end" size="sm" >
                        <CDropdownToggle className='export-dropdown' >Export</CDropdownToggle>
                        <CDropdownMenu className='export-dropdown-menu'>
                            <CDropdownItem as="button" className='export-dropdown-item' onClick={() => { exportJSON([...listData]) }}>JSON</CDropdownItem>
                            <CDropdownItem as="button" className='export-dropdown-item' onClick={() => { exportCSV([...listData]) }}>CSV</CDropdownItem>
                            {onSearchSubmit && <CDropdownItem as="button" className="export-dropdown-item" onClick={handleExportFullQuery}>QUERY</CDropdownItem>}
                        </CDropdownMenu>
                    </CDropdown>
                </div>
                <div className='d-flex justify-content-end'>
                    {onSearchSubmit && (<div className='d-flex justify-content-end'>
                        <TabContainer />
                    </div>)}
                </div>
            </div>
            {listData.length > 0 && !onSearchSubmit && (
                <div className="search-container">
                    <div className='d-flex justify-content-start align-items-center'>
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        <CFormInput
                            className='table-search'
                            id='tableSearch'
                            type="text"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={topControlsTexts.searchPlaceholder}
                        />
                    </div>
                </div>
            )}
            {onSearchSubmit && <SqlControls onQuerySubmit={onSearchSubmit} />}
        </div>
    );
};

export default TopControls;
