import React, { ChangeEvent, useState, useEffect } from 'react';
import Select from 'react-select';
import { CFormSelect, CButton, CFormInput } from '@coreui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSQLBuilder } from '../../hooks/useSQLBuilder';
import './SQLBuilderModal.css';  // The CSS file we created for styling
import LOCALES from '../../locales/en.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { placeholderCSS } from 'react-select/dist/declarations/src/components/Placeholder';

interface SQLBuilderModalProps {
  isVisible: boolean;
  onClose: () => void;
  onQueryChange: (query: string) => void;
}

interface AggregateFunction {
  func: string;
  column: string;
  alias: string;
  useDistinctInCount?: boolean;  // New property to track DISTINCT for COUNT
}

const SQLBuilderModal: React.FC<SQLBuilderModalProps> = ({ isVisible, onClose, onQueryChange }) => {
  const {
    sqlQuery,
    selectedTable,
    setSelectedTable,
    availableColumns,
    selectedColumns,
    setSelectedColumns,
    clauses,
    setClauses,
    aliases,
    setAliases,
    isQueryValid,
    clauseTypes,
    sqlOperators,
    orderByOptions,
    aggregateFunctions,
    tables,
    timestampCondition,  // Timestamp condition from hook
    setTimestampCondition,
    timestampValue1,  // First timestamp value
    setTimestampValue1,
    timestampValue2,  // Second timestamp value for "between"
    setTimestampValue2,
    useDistinct,        // Access global useDistinct state
    setUseDistinct,      // Access setter for global useDistinct
    selectedLogicalOperators,  // Logical operators
    setSelectedLogicalOperators, // Setters for logical operators
    logicalOperators,
    limit,
    setLimit
  } = useSQLBuilder();  // Call the hook to access SQL logic

  const [showAggregateSection, setShowAggregateSection] = useState(false);
  const [showTimestampSection, setShowTimestampSection] = useState(false);
  const [aggregates, setAggregates] = useState<AggregateFunction[]>([]);  // Store aggregates
  const TEXT = LOCALES.components.sqlBuilder;

  // Function to confirm the query and close the modal
  const handleUseQuery = () => {
    onQueryChange(sqlQuery);  // Pass query back to the parent
    onClose();  // Close the modal after the query is used
  };

  // Function to reset all query states
  const handleResetQuery = () => {
    setSelectedTable('');
    setSelectedColumns(['*']);
    setClauses([]);
    setAggregates([]);  // Reset aggregates
    setAliases({});
    setTimestampCondition('');
    setTimestampValue1('');
    setTimestampValue2('');
    setUseDistinct(false);  // Reset DISTINCT
    setSelectedLogicalOperators([]);
  };

  // Function to add a new aggregate function
  const addAggregate = () => {
    setAggregates([...aggregates, { func: '', column: '', alias: '', useDistinctInCount: false }]);  // Include the distinct option for COUNT
  };

  // Function to handle changes in aggregate function, column, alias, or DISTINCT for COUNT
  const handleAggregateChange = (index: number, key: keyof AggregateFunction, value: string | boolean) => {
    const updatedAggregates = aggregates.map((agg, i) =>
      i === index ? { ...agg, [key]: value } : agg
    );
    setAggregates(updatedAggregates);
  };

  // Function to remove an aggregate function
  const removeAggregate = (index: number) => {
    setAggregates(aggregates.filter((_, i) => i !== index));
  };

  // Effect to handle aggregate functions in SQL query
  useEffect(() => {
    if (aggregates.length > 0) {
      const aggregateFuncs = aggregates.map(agg => {
        const aliasPart = agg.alias ? ` AS ${agg.alias}` : '';
        const distinctPart = agg.func === 'COUNT' && agg.useDistinctInCount ? 'DISTINCT ' : '';  // Add DISTINCT inside COUNT if enabled
        return `${agg.func}(${distinctPart}${agg.column})${aliasPart}`;
      });
      setSelectedColumns(aggregateFuncs);
    } else {
      setSelectedColumns(['*']);
    }
  }, [aggregates]);

  const handleLogicalOperatorChange = (index: number, value: string) => {
    const updatedOperators = [...selectedLogicalOperators];
    updatedOperators[index] = value;
    setSelectedLogicalOperators(updatedOperators);
  };


  return (
    <div className={`modal ${isVisible ? 'show' : ''}`} style={{ display: isVisible ? 'flex' : 'none' }} role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* title and x button */}
          <div className="modal-header">
            <h5 className="modal-title">{TEXT.title}</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {/* primer segmento */}
            <div className="builder-section">
              {/* table select */}
              <div className="mt-4">
                <CFormSelect
                  id="tableSelect"
                  value={selectedTable}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedTable(e.target.value as keyof typeof tables)}
                  className="cformselect"
                >
                  <option value="">Select Table</option>
                  {Object.keys(tables).map((table) => (
                    <option key={table} value={table}>
                      {table}
                    </option>
                  ))}
                </CFormSelect>
              </div>
              {/* Multi-select using react-select */}
              <Select
                isMulti
                options={availableColumns.map((column) => ({ label: column, value: column }))}
                onChange={(columns) => setSelectedColumns(columns ? columns.map(c => c.value) : [])}
                placeholder="Select Columns"
                defaultValue={{ label: '*', value: '*' }}
                classNamePrefix="cformselect"
              />
            </div>


            {/* segundo segmento */}
            <div className="builder-section">
              <div className='d-flex gap-3'>
                {/* Add DISTINCT/UNIQUE Toggle Button */}
                <CButton
                  onClick={() => setUseDistinct(!useDistinct)}  // Toggle the global DISTINCT state
                  className={`mt-2 cbutton ${useDistinct ? 'button-selected' : ''}`}
                  style={{ width: '100%' }}
                >
                  {useDistinct ? 'DISTINCT Enabled' : 'Enable DISTINCT'}
                </CButton>

                <CButton
                  style={{ width: '100%' }}
                  onClick={() => setShowTimestampSection(!showTimestampSection)}
                  className={`mt-2 cbutton ${showTimestampSection ? 'button-selected' : ''}`}
                >
                  {showTimestampSection ? 'Hide Date/Time' : 'Add Date/Time'}
                </CButton>
              </div>

              {/* Timestamp picker section */}
              {showTimestampSection && (
                <div className="mt-3">
                  <CFormSelect
                    value={timestampCondition}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => setTimestampCondition(e.target.value)}
                    className="cformselect"
                  >
                    <option value="">Select Condition</option>
                    <option value=">">Greater than</option>
                    <option value="<">Less than</option>
                    <option value="BETWEEN">Between</option>
                  </CFormSelect>

                  {/* Date/Time Pickers */}
                  {timestampCondition && (
                    <div className='d-flex align-items-center gap-3'>
                      <div className="timestamp-container">
                        {/* Selector de Fecha */}
                        <DatePicker
                          selected={timestampValue1 ? new Date(timestampValue1) : null}
                          onChange={(date: Date) => {
                            // Si ya existe un valor, conservamos la hora actual y solo actualizamos la fecha.
                            const currentDate = timestampValue1 ? new Date(timestampValue1) : new Date();
                            currentDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
                            setTimestampValue1(currentDate.toISOString());
                          }}
                          dateFormat="P"                      // Muestra solo la fecha (ej. 02/05/2025)
                          placeholderText="Select Date"
                          className="date-picker"
                        />

                        {/* Selector de Hora */}
                        <DatePicker
                          selected={timestampValue1 ? new Date(timestampValue1) : null}
                          onChange={(time: Date) => {
                            // Si ya existe un valor, conservamos la fecha actual y actualizamos solo la hora.
                            const currentDate = timestampValue1 ? new Date(timestampValue1) : new Date();
                            currentDate.setHours(time.getHours());
                            currentDate.setMinutes(time.getMinutes());
                            setTimestampValue1(currentDate.toISOString());
                          }}
                          showTimeSelect
                          showTimeSelectOnly                 // Muestra únicamente el selector de tiempo
                          timeIntervals={15}                 // Intervalos de 15 minutos (opcional)
                          timeCaption="Time"
                          dateFormat="p"                     // Muestra solo la hora (ej. 08:30 PM)
                          placeholderText="Select Time"
                          className="time-picker"
                        />
                      </div>

                      {timestampCondition === 'BETWEEN' && (
                        <div className="timestamp-container">
                          {/* Selector de Fecha */}
                          <DatePicker
                            selected={timestampValue2 ? new Date(timestampValue2) : null}
                            onChange={(date: Date) => {
                              // Si ya existe un valor, conservamos la hora y actualizamos la fecha.
                              const currentDate = timestampValue2 ? new Date(timestampValue2) : new Date();
                              currentDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
                              setTimestampValue2(currentDate.toISOString());
                            }}
                            dateFormat="P"                      // Formato solo de fecha (ej: 02/05/2025)
                            placeholderText="Select Date"
                            className="date-picker"
                          />

                          {/* Selector de Hora */}
                          <DatePicker
                            selected={timestampValue2 ? new Date(timestampValue2) : null}
                            onChange={(time: Date) => {
                              // Si ya existe un valor, conservamos la fecha y actualizamos la hora.
                              const currentDate = timestampValue2 ? new Date(timestampValue2) : new Date();
                              currentDate.setHours(time.getHours());
                              currentDate.setMinutes(time.getMinutes());
                              setTimestampValue2(currentDate.toISOString());
                            }}
                            showTimeSelect
                            showTimeSelectOnly               // Muestra únicamente el selector de hora
                            timeIntervals={15}                 // Opcional: intervalos de 15 minutos
                            timeCaption="Time"
                            dateFormat="p"                     // Formato para mostrar solo la hora (ej: 08:30 PM)
                            placeholderText="Select Time"
                            className="time-picker"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>


            {/* tercer segmento */}
            <div className="builder-section">
              <div className='d-flex gap-3'>
                <div className='w-50 p-6 overflow-hidden' style={{ maxHeight: 300 }}> {/* Dynamic Clauses Section */}
                  <CButton
                    onClick={() => setClauses([...clauses, { type: '', column: '' }])}
                    className="mt-2 cbutton"
                    style={{ width: '100%' }}
                  >
                    Add Clause
                  </CButton>
                  <div className='add-section-scroll'>
                    {clauses.map((clause, index) => (
                      <div key={index} className="clause-section">
                        <div className='d-flex p-6 justify-content-between '>
                          <CFormSelect
                            value={clause.type}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => setClauses(
                              clauses.map((c, i) => i === index ? { ...c, type: e.target.value } : c)
                            )}
                            className="m-0 cformselect w-85"
                          >
                            <option value="">Select Clause</option>
                            {clauseTypes.map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </CFormSelect>
                          <CButton
                            onClick={() => setClauses(clauses.filter((_, i) => i !== index))}
                            className="remove-button"
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </CButton>
                        </div>

                        <div className='d-flex mt-2 align-items-center gap-1'>
                          {clause.type !== 'LIMIT' && clause.type && (
                            <CFormSelect
                              value={clause.column}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => setClauses(
                                clauses.map((c, i) => i === index ? { ...c, column: e.target.value } : c)
                              )}
                              className="m-0 cformselect w-100"
                            >
                              <option value="">Select Column</option>
                              {availableColumns.map((column) => (
                                <option key={column} value={column}>
                                  {column}
                                </option>
                              ))}
                            </CFormSelect>
                          )}

                          {clause.type === 'LIMIT' && (
                            <div className="flex-fill">
                              <CFormInput
                                id="limitInput"
                                placeholder="Number of rows"
                                value={clause.value || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  const newLimit = e.target.value;
                                  setClauses(
                                    clauses.map((c, i) => i === index ? { ...c, value: newLimit } : c)
                                  );
                                }}
                                className="m-0 w-100 border-0 cformselect"
                                style={{ backgroundColor: '#142A33' }}
                              />
                            </div>
                          )}

                          {index < clauses.length - 1 && clause.type === 'LIMIT' && (
                            <CFormSelect
                              value={selectedLogicalOperators[index] || 'AND'}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLogicalOperatorChange(index, e.target.value)}
                              className="and-or-selector m-0 cformselect"
                            >
                              {logicalOperators.map((operator) => (
                                <option key={operator} value={operator}>
                                  {operator}
                                </option>
                              ))}
                            </CFormSelect>
                          )}

                        </div>
                        {(clause.type === 'WHERE' || clause.type === 'HAVING') && (
                          <div className='flex-fill gap-2 d-flex flex-column mt-2'>
                            <CFormSelect
                              value={clause.operator}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => setClauses(
                                clauses.map((c, i) => i === index ? { ...c, operator: e.target.value } : c)
                              )}
                              className="m-0 cformselect"
                              style={{}}
                            >
                              <option value="">Select</option>
                              {sqlOperators.map((op) => (
                                <option key={op} value={op}>
                                  {op}
                                </option>
                              ))}
                            </CFormSelect>

                            <CFormInput
                              placeholder="Value"
                              value={clause.value}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => setClauses(
                                clauses.map((c, i) => i === index ? { ...c, value: e.target.value } : c)
                              )}
                              className="m-0 w-100 border-0 cformselect"
                              style={{ backgroundColor: '#142A33' }}
                            />
                          </div>
                        )}

                        {clause.type === 'ORDER BY' && (
                          <CFormSelect
                            value={clause.orderByDirection || ''}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => setClauses(
                              clauses.map((c, i) => i === index ? { ...c, orderByDirection: e.target.value } : c)
                            )}
                            className="mt-3 me-2 cformselect"
                            style={{ width: '15%' }}
                          >
                            <option value="">Select Order</option>
                            {orderByOptions.map((direction) => (
                              <option key={direction} value={direction}>
                                {direction}
                              </option>
                            ))}
                          </CFormSelect>
                        )}

                        {index < clauses.length - 1 && clause.type !== 'LIMIT' && (
                          <CFormSelect
                            value={selectedLogicalOperators[index] || 'AND'}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLogicalOperatorChange(index, e.target.value)}
                            className="and-or-selector mt-2 cformselect"
                          >
                            {logicalOperators.map((operator) => (
                              <option key={operator} value={operator}>
                                {operator}
                              </option>
                            ))}
                          </CFormSelect>
                        )}


                      </div>
                    ))}
                  </div>
                </div>

                <div className='w-50 overflow-hidden' style={{ maxHeight: 300 }}>
                  <CButton
                    onClick={addAggregate}
                    className="mt-2 cbutton"
                    style={{ width: '100%' }}
                  >
                    Add Aggregate
                  </CButton>

                  <div className='add-section-scroll'>
                    {aggregates.map((aggregate, index) => (
                      <div key={index} className="aggregate-section">
                        {/* Aggregate Function Selection */}
                        <div className='d-flex p-6 justify-content-between '>
                          <CFormSelect
                            value={aggregate.func}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                              handleAggregateChange(index, 'func', e.target.value)
                            }
                            className="m-0 cformselect w-85"
                            style={{ width: '100%' }}
                          >
                            <option value="">Select Aggregate</option>
                            {aggregateFunctions.map((func) => (
                              <option key={func} value={func}>
                                {func}
                              </option>
                            ))}
                          </CFormSelect>

                          {/* Remove Button */}
                          <CButton
                            onClick={() => removeAggregate(index)}
                            className="remove-button"
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </CButton>
                        </div>


                        {/* Display DISTINCT checkbox for COUNT */}
                        {aggregate.func === 'COUNT' && (
                          <div className="distinct-checkbox mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                              type="checkbox"
                              className="distinct-checkbox-input"
                              checked={aggregate.useDistinctInCount || false}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleAggregateChange(index, 'useDistinctInCount', e.target.checked)
                              }
                            />
                            <span className='distinct' >DISTINCT</span>
                          </div>
                        )}

                        {/* Column Selection */}
                        <CFormSelect
                          value={aggregate.column}
                          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                            handleAggregateChange(index, 'column', e.target.value)
                          }
                          className="mt-2 me-2 cformselect"
                          style={{ width: '100%' }}
                        >
                          <option value="">Select Column</option>
                          {availableColumns.map((column) => (
                            <option key={column} value={column}>
                              {column}
                            </option>
                          ))}
                        </CFormSelect>

                        {/* Alias Input */}
                        <CFormInput
                          placeholder="Alias"
                          value={aggregate.alias}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleAggregateChange(index, 'alias', e.target.value)
                          }
                          className="form-control m-0 w-100 border-0 cformselect"
                          style={{ backgroundColor: '#142A33' }}
                        />


                      </div>
                    ))}
                  </div>
                </div>
              </div>


            </div>



            {/* text box del SQL */}
            <textarea
              rows={4}
              style={{
                width: '100%',
                resize: 'vertical',
              }}
              value={sqlQuery}
              readOnly
              className="custom-textarea"
            />
          </div>

          {/* Add the Reset Query button here */}
          <div className="modal-footer">
            <CButton
              onClick={handleResetQuery}
              className="footer-buttons"
            >
              {TEXT.buttons.reset}
            </CButton>

            <CButton
              onClick={handleUseQuery}
              className="footer-buttons use-button"
            >
              {TEXT.buttons.use}
            </CButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SQLBuilderModal;
