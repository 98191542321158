import React, { ChangeEvent } from 'react';
//import logsState from '../../state/logs/logsState';
import { useSnapshot } from 'valtio';
import store from '../../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faSearch, faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import { CFormInput } from '@coreui/react';
import locales from '../../locales/en.json';



interface PaginationControlsProps {
    currentPage: number;
    totalPages: number;
    totalRows: number;
    onNextPage: () => void;
    onPreviousPage: () => void;
    onSearchSubmit?: (parsedQuery: any) => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({ currentPage, totalPages, totalRows, onNextPage, onPreviousPage, onSearchSubmit }) => {
    const ListView = useSnapshot(store.listView);
    const { searchValue, setSearchValue, listData } = ListView;
    const topControlsTexts = locales.components['topControls'];


    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    if (totalRows <= 0) {
        return null;
    }

    return (
        <div className='d-flex w-100 justify-content-end gap-3'>
            <div className={`d-flex flex-fill justify-content-end ${onSearchSubmit ? 'is-dashed' : ''}`}>
                {listData.length > 0 && onSearchSubmit && (
                    <div className="search-container">
                        <div className='d-flex justify-content-start align-items-center'>
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            <CFormInput
                                className='table-search'
                                id='tableSearch'
                                type="text"
                                value={searchValue}
                                onChange={handleSearchChange}
                                placeholder={topControlsTexts.searchPlaceholder}
                            />
                        </div>
                    </div>
                )}
                {totalRows > 0 && currentPage > 0 && (
                    <span className='d-flex flex-nowrap text-nowrap min-w-max align-items-center pagination-texts me-1' >
                        Records {totalRows}
                    </span>
                )}
            </div>
            <div className='d-flex flex-nowrap text-nowrap min-w-max align-items-center gap-3 me-3'>
                {totalRows > 0 && currentPage > 0 && (
                    <span className=' pagination-texts '>
                        {currentPage > 1 && (
                            <FontAwesomeIcon
                                className='pointer pagination-controls me-2'
                                icon={faLessThan}
                                onClick={onPreviousPage}
                            />
                        )}
                        {currentPage} / {isNaN(totalPages) || totalPages <= 0 ? 1 : totalPages}
                        {currentPage < totalPages && (
                            <FontAwesomeIcon
                                className='pointer pagination-controls ms-2'
                                icon={faGreaterThan}
                                onClick={onNextPage}
                            />
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

export default PaginationControls;
