import React, { useReducer } from 'react';
import './FlexForm.css';
import { useSnapshot } from 'valtio';
import store from './../../state/store';
import formState from '../../state/formState/formState';
import { CButton, CForm, CImage } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMinimize, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import img from '../../assets/img/new-user-img.png';
import UserForm from './UserRegisterForm';
import IntegrationForm from './IntegrationRegisterForm';
import NotificationForm from './NotificationRegisterForm';
import { formReducer, initialState } from './FlexFormReducer';
import useSwalPopups from "../../hooks/useSwal";
import { toast } from 'sonner';

const FlexForm: React.FC = () => {
  const { showError, showSessionExpired } = useSwalPopups();
  const [state, dispatch] = useReducer(formReducer, initialState);
  const FormState = useSnapshot(store.formState);
  const { formType } = FormState;

  const UserState = useSnapshot(store.usersState);
  const { register } = UserState;

  const IntegrationState = useSnapshot(store.integrationState);
  const { addIntegration } = IntegrationState;

  const formatValues = (value: string) => {
    return value.toLowerCase().replace(/\s+/g, '_');
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    switch (formType) {
      case 'user':
        if (!state.email || !state.password || !state.confirmPassword) {
          showError('All fields are required.');
          return;
        }
        if (state.password !== state.confirmPassword) {
          showError('Passwords do not match.');
          return;
        }
        const passwordInput = document.getElementById("password") as HTMLInputElement;
        passwordInput.setCustomValidity("");
        if (passwordInput && !passwordInput.checkValidity()) {
            passwordInput.reportValidity(); // Muestra el tooltip del navegador si no es válido
            return;
        }
        formState.setUser(state.email, state.password, state.isAdmin);
        try {
          const userResponse = await register(state.email, state.password, state.isAdmin);
          switch (userResponse.statusCode) {
            case 201:
            case 200:
              formState.setFormDisplay(false);
              toast.success('User created successfully');
              break;
            case 401:
              showSessionExpired();
              toast.error('Unauthorized. Please check your credentials.');
              break;
            default:
              toast.error('Error creating user. Please try again.');
          }
        } catch (error) {
          console.error(error);
          toast.error('Error creating user. Please try again.');
        }
        break;


      case 'integration':
        console.log(state)
        if (!state.logName || !state.integrationType || !state.authType || !state.logType) {
          showError('All fields are required.');
          return;
        }

        if (state.integrationType === 'AWS') {
          if (state.logType !== 'GuardDuty') {
            if (!state.bucketName || !state.bucketPrefix) {
              showError('Bucket Name and Bucket Prefix are required for AWS integrations.');
              return;
            }
          }
          if (!state.authProfile) {
            showError('Auth Profile is required for AWS integrations.');
            return;
          }
        }
        const integrationPayload: any = {
          new_integration: true,
          log_name: formatValues(state.logName),
          integration_type: state.integrationType,
          auth_type: formatValues(state.authType),
          log_type: state.logType.toLocaleLowerCase(),
          enabled: false,
          client: 'api-dev',
          region: 'us-east-1',
        };

        if (state.integrationType === 'AWS') {
          if (state.authProfile) {
            integrationPayload.auth_profile = state.authProfile;
          }

          if (state.logType !== 'GuardDuty') {
            integrationPayload.bucket_name = state.bucketName;
            integrationPayload.bucket_prefix = state.bucketPrefix;
          } else {
            integrationPayload.bucket_name = '';
            integrationPayload.bucket_prefix = '';
          }
        }
        console.log(integrationPayload)
        const integrationResponse = await addIntegration(integrationPayload);
        console.log(integrationResponse);
        try {
          switch (integrationResponse.statusCode) {
            case 201:
              formState.setFormDisplay(false);
              toast.success('Integration created successfully');
              break;
            case 401:
              showSessionExpired();
              break;
            default:
              toast.error('Error creating integration. Please try again.');
          }
        } catch (error) {
          console.error(error);
          toast.error('Error creating integration. Please try again.');
        }
        break;


      case 'notification':
        if (!state.notificationName || !state.notificationType || !state.channel || !state.webhookURL) {
          showError('All fields are required.');
          return;
        }
        const notificationPayload = {
          notificationName: state.notificationName,
          notificationType: state.notificationType,
          channel: state.channel,
          webhookURL: state.webhookURL
        };
        console.log(notificationPayload);
        break;
    }
  };

  const renderFormFields = () => {
    switch (formType) {
      case 'user':
        return (
          <UserForm
            email={state.email}
            setEmail={(value) => dispatch({ type: 'SET_FIELD', field: 'email', value })}
            password={state.password}
            setPassword={(value) => dispatch({ type: 'SET_FIELD', field: 'password', value })}
            confirmPassword={state.confirmPassword}
            setConfirmPassword={(value) => dispatch({ type: 'SET_FIELD', field: 'confirmPassword', value })}
            passwordVisible={state.passwordVisible}
            togglePasswordVisibility={() => dispatch({ type: 'TOGGLE_PASSWORD_VISIBILITY' })}
            isAdmin={state.isAdmin}
            setIsAdmin={(value) => dispatch({ type: 'SET_FIELD', field: 'isAdmin', value })}
            errorMessage={state.errorMessage}
          />
        );
      case 'integration':
        return (
          <IntegrationForm
            logName={state.logName}
            setLogName={(value) => dispatch({ type: 'SET_FIELD', field: 'logName', value })}
            integrationType={state.integrationType}
            setIntegrationType={(value) => dispatch({ type: 'SET_FIELD', field: 'integrationType', value })}
            authType={state.authType}
            setAuthType={(value) => dispatch({ type: 'SET_FIELD', field: 'authType', value })}
            logType={state.logType}
            setLogType={(value) => dispatch({ type: 'SET_FIELD', field: 'logType', value })}
            integrationData={state.integrationData}
            setIntegrationData={(value) => dispatch({ type: 'SET_FIELD', field: 'integrationData', value })}
            errorMessage={state.errorMessage}
            // Nuevos campos para AWS y opciones adicionales
            authProfile={state.authProfile}
            setAuthProfile={(value) => dispatch({ type: 'SET_FIELD', field: 'authProfile', value })}
            bucketName={state.bucketName}
            setBucketName={(value) => dispatch({ type: 'SET_FIELD', field: 'bucketName', value })}
            bucketPrefix={state.bucketPrefix}
            setBucketPrefix={(value) => dispatch({ type: 'SET_FIELD', field: 'bucketPrefix', value })}
          />
        );
      case 'notification':
        return (
          <NotificationForm
            notificationName={state.notificationName}
            setNotificationName={(value) => dispatch({ type: 'SET_FIELD', field: 'notificationName', value })}
            notificationType={state.notificationType}
            setNotificationType={(value) => dispatch({ type: 'SET_FIELD', field: 'notificationType', value })}
            channel={state.channel}
            setChannel={(value) => dispatch({ type: 'SET_FIELD', field: 'channel', value })}
            webhookURL={state.webhookURL}
            setWebhookURL={(value) => dispatch({ type: 'SET_FIELD', field: 'webhookURL', value })}
            errorMessage={state.errorMessage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CForm className='flex-form' onSubmit={handleSubmit}>
      <div className='form-body'>
        <div className='form-title' onClick={() => dispatch({ type: 'TOGGLE_FORM_MINIMIZE' })}>
          <label>#01 Creating new {formType}...</label>
          <CButton className="close-button">
            <FontAwesomeIcon
              className='close-icon'
              icon={state.isFormMinimized ? faChevronUp : faWindowMinimize}
            />
          </CButton>
        </div>
        {!state.isFormMinimized && (
          <div className='scroll'>
            <div className='new-user-form'>
              {formType !== 'integration' && <CImage src={img} />}
            </div>
            <div className='flex-form-group'>
              {renderFormFields()}
              <div className='d-flex gap-3 w-100 justify-content-center'>
                <CButton className='btn-cancel' type="button" onClick={() => formState.setFormDisplay(false)}>Cancel</CButton>
                <CButton
                  className={`
                    ${formType === 'user' && (!state.email || !state.password || !state.confirmPassword) ? 'disabled btn-disabled' : ''} 
                    ${formType === 'integration' && (!state.logName || !state.integrationType || !state.authType || !state.logType) ? 'disabled btn-disabled' : ''}
                    ${formType === 'notification' && (!state.notificationName || !state.notificationType || !state.channel || !state.webhookURL) ? 'disabled btn-disabled' : ''} 
                    btn-solidarity
                  `}
                  type='submit'
                  disabled={
                    (formType === 'user' && (!state.email || !state.password || !state.confirmPassword)) ||
                    (formType === 'integration' && (!state.logName || !state.integrationType || !state.authType || !state.logType)) ||
                    (formType === 'notification' && (!state.notificationName || !state.notificationType || !state.channel || !state.webhookURL))
                  }
                >
                  Submit
                </CButton>

              </div>
            </div>
          </div>
        )}
      </div>
    </CForm>
  );
};


export default FlexForm;
