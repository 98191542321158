// UserForm.tsx
import React from 'react';
import { CFormInput, CImage } from '@coreui/react';
import eyeOpen from '../../assets/img/Eye-open.svg';
import eyeClose from '../../assets/img/Eye-close.svg';

interface UserFormProps {
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (password: string) => void;
    confirmPassword: string;
    setConfirmPassword: (confirmPassword: string) => void;
    passwordVisible: boolean;
    togglePasswordVisibility: () => void;
    isAdmin: boolean;
    setIsAdmin: (isAdmin: boolean) => void;
    errorMessage: string;
}

const UserForm: React.FC<UserFormProps> = ({
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    passwordVisible,
    togglePasswordVisibility,
    isAdmin,
    setIsAdmin,
    errorMessage,
}) => {
    return (
        <>
            <label className='flex-form-input'>
                Email
                <div className="input-container">
                    <CFormInput
                        type="email"
                        placeholder="email"
                        value={email}
                        onChange={(e:any) => setEmail(e.target.value)}
                        aria-label="email"
                    />
                </div>
            </label>
            <label className='flex-form-input'>
                Password
                <div className="input-container">
                    <CFormInput
                        id="password" 
                        type={passwordVisible ? "text" : "password"}
                        placeholder="password"
                        value={password}
                        onChange={(e:any) => setPassword(e.target.value)}
                        aria-label="password"
                        pattern="^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$"
                        title="La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un símbolo."
                        required
                    />
                    <CImage
                        className='eye-icon'
                        src={passwordVisible ? eyeOpen : eyeClose}
                        onClick={togglePasswordVisibility}
                    />
                </div>
            </label>
            <label className='flex-form-input'>
                Confirm
                <div className="input-container">
                    <CFormInput
                        type={passwordVisible ? "text" : "password"}
                        placeholder="password"
                        value={confirmPassword}
                        onChange={(e:any) => setConfirmPassword(e.target.value)}
                        aria-label="confirm password"
                        pattern="^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$"
                        title="La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un símbolo."
                        required
                    />
                    <CImage
                        className='eye-icon'
                        src={passwordVisible ? eyeOpen : eyeClose}
                        onClick={togglePasswordVisibility}
                    />
                </div>
            </label>
            {/* <label className='flex-form-input is-admin-checkbox'>
                Admin
                <input
                    type="checkbox"
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                />
            </label> */}
            {errorMessage && <p className='error-message shake'>{errorMessage}</p>}
        </>
    );
};

export default UserForm;
