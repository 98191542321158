import React, { useState } from 'react';
import { CSidebar, CSidebarNav, CNavItem, CNavGroup, CImage } from '@coreui/react';
import Logo from '../../assets/img/logo.png';
import Tab from '../../assets/img/tab.png';
import "./menu.css";
import menuData from '../../locales/en.json';
import store from '../../state/store';
import { useSnapshot } from 'valtio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Menu = () => {
    const MenuState = useSnapshot(store.menuState)
    const { isOpen, toggleMenu } = MenuState
    const menuText = menuData.components.menu
    const [openSubMenuTop, setOpenSubMenuTop] = useState(null);
    const [openSubMenuBottom, setOpenSubMenuBottom] = useState(null);

    const toggleSubMenuTop = (index) => {
        setOpenSubMenuTop(openSubMenuTop === index ? null : index);
    };

    const toggleSubMenuBottom = (index) => {
        setOpenSubMenuBottom(openSubMenuBottom === index ? null : index);
    };

    const handleMenuClick = () => {
        setOpenSubMenuTop(null);
        setOpenSubMenuBottom(null);
    };

    const handleTabClick = () => {
        toggleMenu()
    };

    const importIcon = (iconName) => {
        try {
            return require(`../../assets/img/${iconName}.svg`);
        } catch (error) {
            console.error(`Error importing icon: ${iconName}`, error);
            return null;
        }
    };

    return (
        <div>
            <div className='menu-header'>
                <div>
                    <a className='home-link' href="/dashboards-all" >{menuText.menuHeader.label1}</a>
                    <label>{menuText.menuHeader.label2}</label>
                </div>
                <CImage fluid src={Logo} />
            </div>
            <div className={!isOpen ? "paddle open" : "paddle"}>
                <CSidebar className="collapside">
                    <CSidebarNav>
                        {menuText.options.map((option, index) => {
                            const icon = importIcon(option.icon);
                            return option.submenu.length > 0 ? (
                                <CNavGroup
                                    key={index}
                                    toggler={<span className={`menu-item ${openSubMenuTop === index ? 'selected' : (openSubMenuTop != null && openSubMenuTop !== index) ? 'unselected' : ''}`}>
                                        <CImage className='menu-icon' src={icon} />
                                        <span className={`${openSubMenuTop === index ? 'dashed' : ''}`}>{option.label}</span>
                                        <FontAwesomeIcon icon={faCaretDown} className={`down-arrow ${openSubMenuTop === index ? 'selected' : (openSubMenuTop != null && openSubMenuTop !== index) ? 'unselected' : ''}`} />
                                    </span>}
                                    onClick={() => toggleSubMenuTop(index)}
                                >
                                    {option.submenu.map((item, subIndex) => {
                                        const icons = importIcon(item.icon);
                                        return (
                                            <CNavItem key={subIndex} className='submenu-item' href={item.href}>
                                                <CImage className='menu-icon' src={icons} />
                                                {item.text}
                                            </CNavItem>
                                        );
                                    })}
                                </CNavGroup>
                            ) : (
                                <CNavItem
                                    key={index}
                                    className={`menu-item ${(openSubMenuTop != null && openSubMenuTop !== index) ? 'unselected' : ''}`}
                                    href={option.href}
                                    onClick={handleMenuClick}
                                >
                                    <CImage className='menu-icon' src={icon} />
                                    {option.label}
                                </CNavItem>
                            );
                        })}

                    </CSidebarNav>
                    <CSidebarNav className="down-sidebar">
                        {menuText.optionsDown.map((option, index) => {
                            const icon = importIcon(option.icon);
                            return option.submenu.length > 0 ? (
                                <CNavGroup
                                    key={index}
                                    toggler={
                                        <span
                                            className={`down-menu menu-item ${openSubMenuBottom === index
                                                    ? 'selected'
                                                    : openSubMenuBottom != null &&
                                                        openSubMenuBottom !== index
                                                        ? 'unselected'
                                                        : ''
                                                }`}
                                        >
                                            <CImage className="menu-icon" src={icon} />
                                            {option.label}
                                        </span>
                                    }
                                    onClick={() => toggleSubMenuBottom(index)}
                                >
                                    {option.submenu.map((item, subIndex) => {
                                        const subIcon = importIcon(item.icon); // Carga el icono del submenú
                                        return (
                                            <CNavItem
                                                key={subIndex}
                                                className="submenu-item"
                                                href={item.href}
                                            >
                                                <CImage className="menu-icon" src={subIcon} />
                                                {item.text}
                                            </CNavItem>
                                        );
                                    })}
                                </CNavGroup>
                            ) : (
                                <CNavItem
                                    key={index}
                                    className={`down-menu menu-item ${openSubMenuBottom != null && openSubMenuBottom !== index
                                            ? 'unselected'
                                            : ''
                                        }`}
                                    href={option.href}
                                    onClick={handleMenuClick}
                                >
                                    <CImage className="menu-icon" src={icon} />
                                    {option.label}
                                </CNavItem>
                            );
                        })}
                    </CSidebarNav>
                </CSidebar>
                <div onClick={handleTabClick}>
                    <img alt="tabimage" className="menu-tab" src={Tab} />
                </div>
            </div>
        </div>
    );
}

export default Menu;
