import React from 'react';
import Stack from '@mui/material/Stack';
import LoadingBar from '../assets/img/Loading.gif';
import { CImage } from '@coreui/react';

interface LoadingProps {
  marginTop?: number;
}

const Loading: React.FC<LoadingProps> = ({ marginTop }) => {
  return (
    <Stack spacing={2} direction="row" sx={{ mt: marginTop }} className='justify-content-center '>
      <CImage src={LoadingBar} width={100} />
    </Stack>
  );
};

export default Loading;
