import React, { useState, ChangeEvent } from 'react';
import { CSpinner } from '@coreui/react';
import tabState from '../../state/tabs/tabState';
import { useSnapshot } from 'valtio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';

const TabContainer: React.FC = () => {
    const snapshot = useSnapshot(tabState);
    const [renamingTabId, setRenamingTabId] = useState<number | null>(null);
    const [newTabName, setNewTabName] = useState<string>('');

    const handleRenameTab = (id: number, newName: string) => {
        tabState.renameTab(id, newName);
        setRenamingTabId(null);
    };

    const handleCloseTab = (id: number) => {
        tabState.closeTab(id);
    };

    const handleSetActiveTab = (id: number) => {
        tabState.setActiveTab(id);
    };

    return (
        <div className="tabs-container">
            {snapshot.tabs.map(tab => (
                <div
                    key={tab.id}
                    className={`tab ${tab.id === snapshot.activeTabId ? 'active' : ''}`}
                    onClick={() => handleSetActiveTab(tab.id)}
                >
                    {renamingTabId === tab.id ? (
                        <input
                            value={newTabName || ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewTabName(e.target.value)}
                            onBlur={() => handleRenameTab(tab.id, newTabName)}
                            onKeyPress={(e) => e.key === 'Enter' && handleRenameTab(tab.id, newTabName)}
                            autoFocus
                        />
                    ) : (
                        <span onDoubleClick={() => { setRenamingTabId(tab.id); setNewTabName(tab.name); }}>
                            {tab.name}
                        </span>
                    )}
                    <button
                        className="close-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCloseTab(tab.id);
                        }}
                    >
                        <FontAwesomeIcon className='back-nav' icon={faTimes} />
                    </button>

                    {tab.loading && tab.id === snapshot.activeTabId && (
                        <CSpinner color="warning" size="sm" className="ms-2" />
                    )}
                </div>
            ))}
            <button
                className={`tab add-button ${tabState.tabs.length >= 10 ? 'disable' : ''}`}
                onClick={() => {
                    if (tabState.tabs.length < 10) {
                        tabState.addTab();
                    }
                }}
                disabled={tabState.tabs.length >= 10} // También desactiva el botón
            >
                <FontAwesomeIcon className='back-nav' icon={faPlus} />
            </button>
        </div>
    );
};

export default TabContainer;
